import { Button } from "primereact/button";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="surface-0 grid m-0 mt-8 p-4">
        <div className="col-12 md:col-4">
          <div className="font-italic flex align-items-center justify-content-center md:justify-content-start text-primary text-3xl font-bold ">
            <i
              className="pi pi-bolt"
              style={{ fontSize: 35, paddingRight: 2 }}
            />
            Doing-Sports
          </div>
        </div>
        <div className="col-12 md:col-4 flex flex-column justify-content-start  pb-4 gap-2">
          <div className="font-italic text-center text-2xl font-bold">
            Encontranos en
          </div>
          <div className="flex justify-content-center">
            <i
              className="pi pi-instagram"
              style={{ fontSize: 35, paddingRight: 2 }}
            />
          </div>
        </div>
        <div className="col-12 md:col-4 flex flex-column text-center text-800  pb-4 gap-2">
          <div className="font-italic   text-2xl font-bold ">Contáctanos</div>
          <div className="text-700 font-medium ">doing.sports.ar@gmail.com</div>
          <div className="text-700 font-medium  flex align-items-center justify-content-center">
            Vía WhatsApp{" "}
            <Link to={`https://wa.me/+5491126070728`} target="_blanck">
              <Button
                severity="success"
                text
                size="small"
                icon="pi pi-whatsapp"
                tooltip="Envíar whatsapp"
                tooltipOptions={{ position: "top" }}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 text-center surface-0 text-sm">
        © 2024 by Doing-Code
      </div>
    </>
  );
};

export default Footer;
