import { useNavigate } from "react-router-dom";
import { useVerifyRol } from "./useVerifyRols";

export const usePanelItems = () => {
  const navigate = useNavigate();
  const { verifyRol } = useVerifyRol();

  const items = [
    ...(verifyRol({ SA: true })
      ? [
          {
            label: "Dashboard",
            icon: "pi pi-chart-bar",
            command: () => navigate("/panel/dashboard"),
            path: "/panel/dashboard",
          },
        ]
      : []),

    {
      label: "Mí perfil",
      icon: "pi pi-id-card",
      command: () => navigate("/panel"),
      path: "/panel",
    },

    {
      label: "Deportes",
      icon: "pi pi-discord",
      items: [
        {
          label: "Ver cuotas",
          icon: "pi pi-clipboard",
          command: () => navigate("/panel/sports"),
          path: "/panel/sports",
        },
        {
          label: "Ver solicitudes",
          icon: "pi pi-bell",
          command: () => navigate("/panel/sports/requests"),
          path: "/panel/sports/requests",
        },
        {
          label: "Buscar deportes",
          icon: "pi pi-search",
          command: () => navigate("/panel/sports/search"),
          path: "/panel/sports/search",
        },
      ],
    },

    {
      label: "Mis instituciones",
      icon: "pi pi-th-large",
      command: () => navigate("/panel/my-institutions"),
      path: "/panel/my-institutions",
    },

    ...(verifyRol({ A: true })
      ? [
          {
            label: "Usuarios",
            icon: "pi pi-user",
            items: [
              {
                label: "Ver usuarios",
                icon: "pi pi-users",
                command: () => navigate("/panel/users"),
                path: "/panel/users",
              },
              {
                label: "Nuevo usuario",
                icon: "pi pi-user-plus",
                command: () => navigate("/panel/users/new"),
                path: "/panel/users/new",
              },
            ],
          },
        ]
      : []),
    ...(verifyRol({ SA: true, A: true })
      ? [
          {
            label: "Instituciones",
            icon: "pi pi-th-large",
            items: [
              {
                label: "Ver instituciones",
                icon: "pi pi-list",
                command: () => navigate("/panel/institutions"),
                path: "/panel/institutions",
              },
              {
                label: "Nueva institución",
                icon: "pi pi-plus",
                command: () => navigate("/panel/institutions/new"),
                path: "/panel/institutions/new",
              },
              {
                label: "Adm deportes",
                icon: "pi pi-file-edit",
                command: () => navigate("/panel/manage-sports"),
                path: "/panel/manage-sports",
              },
            ],
          },
          {
            label: "Pagos",
            icon: "pi pi-wallet",
            items: [
              {
                label: "Ver cuotas",
                icon: "pi pi-credit-card",
                command: () => navigate("/panel/payments"),
                path: "/panel/payments",
              },
              ...(verifyRol({ SA: true })
                ? [
                    {
                      label: "Ver planes",
                      icon: "pi pi-folder-open",
                      command: () => navigate("/panel/plans"),
                      path: "/panel/plans",
                    },
                    {
                      label: "Nuevo plan",
                      icon: "pi pi-folder-plus",
                      command: () => navigate("/panel/plans/new"),
                      path: "/panel/plans/new",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  return items;
};

// ...(verifyRol({ SA: true })
//   ? [
//       {
//         label: "Planes",
//         icon: "pi pi-folder",
//         items: [
//           {
//             label: "Ver planes",
//             icon: "pi pi-folder-open",
//             command: () => navigate("/panel/plans"),
//             path: "/panel/plans",
//           },
//           {
//             label: "Nuevo plan",
//             icon: "pi pi-folder-plus",
//             command: () => navigate("/panel/plans/new"),
//             path: "/panel/plans/new",
//           },
//         ],
//       },
//     ]
//   : []),
